<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:body>
        <b-row>
          <b-col xs="12" sm="12" md="8">
              <b-form-group
              class="row mb-0"
              label-cols-sm="4"
              label-for="org_id"
              :label="$t('irrigation_task.Please Select Organization')"
              >
              <b-form-select
                  plain
                  v-model="search.org_id"
                  :options="$store.state.orgList"
                  >
                  <template v-slot:first>
                  <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
              </b-form-select>
            </b-form-group>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md='12'>
          <iq-card>
          <template v-slot:headerTitle>
            <h4 class='card-title'>{{ $t('Organogram') }}</h4>
          </template>
          <template v-slot:body>
            <div class="d-flex justify-content-center" v-if="loader">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <slot v-else>
              <tree :data="datas" :link="datas" v-if="datas.length>0"></tree>
              <div class="panel-body text-center" v-else>
                <h5 class="text-danger">{{ $t('globalTrans.noDataFound') }}</h5>
              </div>
            </slot>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters } from 'vuex'
import RestApi, { commonServiceBaseUrl } from '@/config/api_config'
import { designationToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'
import tree from './tree'

export default {
  name: 'list',
  mixins: [ModalBaseMasterList],
  components: {
    tree
  },
  data () {
    return {
      datas: [],
      search: {
        org_id: 0
      },
      loader: false,
      rows: []
    }
  },
  computed: {
    formTitle () {
       return (this.editItemId === 0) ? this.$t('organogram.designation_entry') : this.$t('organogram.designation') + ' ' + this.$t('globalTrans.modify')
    },
    orgList () {
      return this.$store.state.commonObj.organizationProfileList
    },
    currentLocale () {
      return this.$i18n.locale
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('organogram.designation_name'), class: 'text-left' },
          { label: this.$t('organogram.direct_post'), class: 'text-left' },
          { label: this.$t('organogram.promotional_post'), class: 'text-left' },
          { label: this.$t('organogram.total_post'), class: 'text-left' },
          { label: this.$t('organogram.grade_of_post'), class: 'text-left' },
          { label: this.$t('organogram.sorting_order'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'designation_bn' },
          { key: 'direct_post' },
          { key: 'promotional_post' },
          { key: 'total_post' },
          { key: 'grade_name_bn' },
          { key: 'sorting_order' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'designation' },
          { key: 'direct_post' },
          { key: 'promotional_post' },
          { key: 'total_post' },
          { key: 'grade_name' },
          { key: 'sorting_order' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
      })
    },
    ...mapGetters({
      authUser: 'Auth/authUser'
    })
  },
  watch: {
    'search.org_id': function (newVal, oldVal) {
      this.loadData()
    }
  },
  mounted () {
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id
      })
    }
    this.loadData()
  },
  methods: {
    onOver () {
      this.$refs.dropdown.visible = true
    },
    onLeave () {
      this.$refs.dropdown.visible = false
    },
    searchData () {
      if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.org_id
        })
      } else if (this.authUser.role_id === 0) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.office_detail.org_id
        })
      }
      this.loadData()
    },
    remove (item) {
      this.changeStatus(commonServiceBaseUrl, designationToggleStatus, item, 'common', 'designationList')
    },
    eachRecursive (obj, isFirst = false) {
      const listData = obj
        const tmpData = listData.map(item => {
          if (item.all_children_office.length > 0) {
            this.eachRecursive(item.all_children_office, false)
          }
        return Object.assign(item, { show: !item.parent_office_id, icon: 'fas fa-plus' })
      })
      return tmpData
    },
    loadData () {
      this.loader = true
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(commonServiceBaseUrl, 'master-office/organogram', this.search).then(response => {
        if (response.success) {
          this.datas = this.eachRecursive(response.data, true)
          this.loader = false
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    }
  }
}
</script>
