<template>
  <b-container fluid>
    <b-row>
      <div>
        <ul class="tree">
            <!-- <pre>{{data}}</pre> -->
          <li v-for='(val, key) in data' v-show='val.show' :key="key">
            <slot v-if="val.all_children_office.length>0">
              <div @click='toggle(val.all_children_office,val)'>
                <i :class="val.icon"></i>
                <span @mouseover="mouseOver(val)" style="cursor:pointer;margin-left:5px" :id="'popover-target-'+ val.id">
                  {{ ($i18n.locale==='bn') ? val.office_name_bn  :  val.office_name  }}
                </span>
              </div>
            </slot>
            <slot v-else>
              <span @mouseover="mouseOver(val)" style="margin-left:2px" :id="'popover-target-'+ val.id">{{ ($i18n.locale==='bn') ? val.office_name_bn  :  val.office_name  }}</span>
            </slot>
            <tree v-if="val.all_children_office.length>0" :data='val.all_children_office' :link='val.all_children_office'>
            </tree>
            <b-popover :target="'popover-target-'+ val.id" triggers="hover" placement="right">
              <template #title>{{ $t('organogram.DesignationWiseUser') }}</template>
              <div class="d-flex justify-content-center" v-if="loader">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <ul class="list-group" v-else>
                <slot v-if="designations.length>0">
                  <li class="list-group-item d-flex justify-content-between align-items-center" v-for='(designation, index) in designations' :key="index">
                    {{ ($i18n.locale==='bn') ? designation.designation.designation_bn  :  designation.designation.designation  }}
                    <span class="badge badge-primary badge-pill"> {{designation.total}}</span>
                  </li>
                </slot>
                <div class="panel-body text-center" v-else>
                  <h6 v-if="is_hit" class="text-danger">{{ $t('globalTrans.noDataFound') }}</h6>
                </div>
              </ul>
            </b-popover>
          </li>
        </ul>
      </div>
    </b-row>
  </b-container>
</template>
<style scoped>
.tree, .tree ul {
    margin:0;
    padding:0;
    list-style:none
}
.tree ul {
    margin-left:1em;
    position:relative
}
.tree ul ul {
    margin-left:.5em
}
.tree ul:before {
    content:"";
    display:block;
    width:0;
    position:absolute;
    top:0;
    bottom:0;
    left:0;
    border-left:1px solid
}
.tree li {
    margin:0;
    padding:0 1em;
    line-height:2em;
    color:#369;
    font-weight:700;
    position:relative
}
.tree ul li:before {
    content:"";
    display:block;
    width:10px;
    height:0;
    border-top:1px solid;
    margin-top:-1px;
    position:absolute;
    top:1em;
    left:0
}
.tree ul li:last-all_children_office:before {
    background:#fff;
    height:auto;
    top:1em;
    bottom:0
}
.indicator {
    margin-right:5px;
}
.tree li a {
    text-decoration: none;
    color:#369;
}
.tree li button, .tree li button:active, .tree li button:focus {
    text-decoration: none;
    color:#369;
    border:none;
    background:transparent;
    margin:0px 0px 0px 0px;
    padding:0px 0px 0px 0px;
    outline: 0;
}
.popover {
  width: 600px;
}
</style>
<script>
import RestApi, { commonServiceBaseUrl } from '@/config/api_config'
import tree from './tree'
export default {
  name: 'tree',
  props: ['data', 'link'],
  mounted () {
  },
  components: {
    tree
  },
  data () {
    return {
      loader: true,
      is_hit: false,
      designations: []
    }
  },
  computed: {
    currentLocale () {
      return this.$i18n.locale
    }
  },
  watch: {
  },
  methods: {
    mouseOver: function (data) {
      const newData = {
        office_id: data.id
      }
      RestApi.getData(commonServiceBaseUrl, 'master-office/office-wise-people', newData).then(response => {
        if (response.success) {
          this.designations = response.data
          this.loader = false
          this.is_hit = true
        }
      })
    },
    isLeaf: function (node) {
      return typeof node.all_children_office !== 'object'
    },
    toggle: function (value, val) {
      if (val.icon === 'fas fa-plus') {
        val.icon = 'fas fa-minus-circle'
      } else if (val.icon === 'fas fa-minus-circle') {
        val.icon = 'fas fa-plus'
      }
      for (const nodeName in value) {
        value[nodeName].show = !value[nodeName].show
      }
    }
  }
}
</script>
